import React, { useState } from "react"
import { FaEnvelope, FaLock } from 'react-icons/fa'
import { useDispatch } from "react-redux"
import { navigate } from "gatsby"

import "./login.scss"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "../components/image"
import Header from "../components/header/header"
import Footer from "../components/footer/footer"
import ResetPassword from "../containers/resetPassword/resetPassword"
import { login } from "../redux/api/apiAction"
import { loginCoordinator } from "../redux/group/groupAction";


const LoginPage = () => {
    const dispatch = useDispatch()

    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)
    const [isCoordinator, setIsCoordinator] = useState(false)

    const [password, setPassword] = useState(process.env.NODE_ENV !== 'production' ? "sdf" : "")
    const [email, setEmail] = useState(process.env.NODE_ENV !== 'production' ? "antoine.delagarcie@gmail.com" : "")

    const [validationPassword, setValidationPassword] = useState("")
    const [validationEmail, setValidationEmail] = useState("")

    const onChange = e => {
        const { name, value } = e.target
        name === "email" ? setEmail(value) : setPassword(value)
    }

    const checkValidation = () => {
        if (email === "")
            setValidationEmail("Enter your email")
        else
            setValidationEmail("")

        if (password === "")
            setValidationPassword("Enter your password")
        else
            setValidationPassword("")

        return email.length > 0 && password.length > 0
    }

    const onSubmit = e => {
        e.preventDefault()

        if (checkValidation()) {
            if (isCoordinator) {
                dispatch(loginCoordinator(email, password))
                    .then(res => {
                        if (res === true) {
                            setEmail("")
                            setPassword("")
                            setSuccess(true)
                            setError(false)
                            navigate("/group")
                        }
                        else {
                            setSuccess(false)
                            setError(true)
                        }
                    })
            }
            else {
                dispatch(login(email, password))
                    .then(res => {
                        if (res === true) {
                            setEmail("")
                            setPassword("")
                            setSuccess(true)
                            setError(false)
                            navigate("/individual")
                        }
                        else {
                            setSuccess(false)
                            setError(true)
                        }
                    })
            }
        }
        else {
            setSuccess(false)
            setError(true)
        }
    }

    return (
        <Layout main={false} className={"login"}>
            <SEO title="Login" />
            {/* <header class="mt-5">
                <p class="title">The Union World Conference on Lung Health 2022</p>
                <p class="subtitle">Log into your account</p>
            </header> */}
            <Header title={null}
                subtitle={"Log into your account"}
                image={<Image filename={"android-chrome-384x384.png"} alt={"Union 22"} />}
            />
            <form onSubmit={onSubmit}>
                <div className="form-row">
                    <div className={"col-1"}>
                        <label><FaEnvelope /></label>
                    </div>
                    <div className={"col-11"}>
                        <input type="email" className="form-control" placeholder="Email" name={"email"} value={email} onChange={onChange} required />
                        {validationEmail.length > 0 ?
                            <div className="invalid-tooltip">
                                {validationEmail}
                            </div> : ""}
                    </div>
                </div>
                <div className="form-row">
                    <div className={"col-1"}>
                        <label><FaLock /></label>
                    </div>
                    <div className={"col-11"}>
                        <input type="password" className="form-control" placeholder="Password" name={"password"} value={password} onChange={onChange} required />
                        {validationPassword.length > 0 ?
                            <div className="invalid-tooltip">
                                {validationPassword}
                            </div> : ""}
                    </div>
                </div>
                <div className="form-row">
                    <div className={"col-12"}>
                        <label htmlFor={"isCoordinator"}>Are you a group coordinator ?<br />Tick the box please</label>
                    </div>
                    <div className={"col-12"}>
                        <input type="checkbox" id={"isCoordinator"} className="form-control form-control-sm" placeholder="Coordinator" name={"isCoordinator"} value={isCoordinator} onChange={() => setIsCoordinator(prev => !prev)} />
                    </div>
                </div>
                <div className={"form-row"}>
                    {error ?
                        <div className="error invalid-tooltip">
                            Error during connection
                        </div>
                        : ""}

                    {success ?
                        <div className="success valid-feedback">
                            Connection succeeded
                        </div>
                        : ""}
                </div>
            </form>
            <footer className={"sr-footer"}>
                <div>
                    <button className={"btn btn-info btn-round"} onClick={onSubmit}>Log in</button>
                    <ResetPassword isCoordinator={isCoordinator} />

                </div>
            </footer>
        </Layout>
    )
}

export default LoginPage
